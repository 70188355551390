<template>
	<div>
		<v-layout class="align-center light-blue-bg px-4 py-2" style="min-height: 140px">
			<v-flex md12>
				<div class="d-flex justify-end">
					<v-btn v-on:click="goBack()" color="" class="ml-2" depressed tile>
						<v-icon small left>mdi-arrow-left</v-icon>Back
					</v-btn>
				</div>
				<div class="d-flex">
					<div class="d-flex pb-3 w-33">
						<div>
							<div>
								<span class="fw-600">Project # : </span>
								<span class="fw-500">
									<!-- {{ detail }} -->
									<v-chip
										v-if="detail?.project_barcode"
										color="cyan"
										class="ml-2"
										label
										text-color="white"
										>{{ detail.project_barcode }}</v-chip
									>
									<span class="ml-1">
										{{ detail?.project_name }}
									</span>
								</span>
							</div>
							<div class="mt-3">
								<span class="fw-600">Project Cost : </span>
								<span class="fw-500">
									<ShowPrice
										truncate
										:object="detail"
										object-key="project_cost"
										label="Project Cost"
									></ShowPrice>
								</span>
							</div>
							<div class="mt-3">
								<span class="fw-600">Project Value : </span>
								<span class="fw-500">
									<ShowPrice
										truncate
										:object="detail"
										object-key="project_value"
										label="Project Value"
									></ShowPrice>
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex pb-3 w-33">
						<div>
							<div>
								<span class="fw-600">Budget Name : </span>
								<span class="fw-500">
									<ShowValue
										truncate
										:object="detail"
										object-key="budget_name"
										label="Budget Name"
									></ShowValue>
								</span>
							</div>
							<div class="mt-3">
								<span class="fw-600">Budget Cost : </span>
								<span class="fw-500">
									<ShowPrice
										truncate
										:object="detail"
										object-key="budget_cost"
										label="Budget Cost"
									></ShowPrice>
								</span>
							</div>
							<div v-if="detail?.subbudget_name" class="mt-3">
								<span class="fw-600">Sub Budget Name : </span>
								<span class="fw-500">
									<ShowValue
										truncate
										:object="detail"
										object-key="subbudget_name"
										label="Sub Budget Name"
									></ShowValue>
								</span>
							</div>
							<div v-if="detail?.subbudget_cost" class="mt-3">
								<span class="fw-600">Sub Budget Cost : </span>
								<span class="fw-500">
									<ShowPrice
										truncate
										:object="detail"
										object-key="subbudget_cost"
										label="Sub Budget Cost"
									></ShowPrice>
								</span>
							</div>
						</div>
					</div>
				</div>
			</v-flex>
		</v-layout>
		<v-tabs
			v-model="tabs"
			background-color="transparent"
			color="blue"
			class="custom-tab-transparent"
			active-class="blue darken-4 text-white"
			hide-slider
		>
			<v-tab href="#expense-spend">
				<inline-svg
					:fill="tabs === 'expense-spend' ? 'white' : 'Gray'"
					:src="$assetURL('media/custom-svg/expense-icon.svg')"
					height="15"
					width="15"
					class="mr-2"
				/>
				Expense Spend</v-tab
			>
			<v-tab href="#purchase-order">
				<inline-svg
					:fill="tabs === 'purchase-order' ? 'white' : 'Gray'"
					:src="$assetURL('media/custom-svg/purchase-order.svg')"
					height="15"
					width="15"
					class="mr-2"
				/>
				Purchase Order Spend</v-tab
			>
		</v-tabs>
		<v-tabs-items v-model="tabs" class="mx-3 mt-4">
			<v-tab-item value="expense-spend" class="pb-2" v-if="tabs === 'expense-spend'">
				<div>
					<div class="d-flex pb-2">
						<inline-svg
							fill="#2666cb"
							:src="$assetURL('media/custom-svg/expense-icon.svg')"
							height="20"
							width="20"
							class="mr-2"
						/>
						<h5 style="color: #0d47a1">Expense Spend</h5>
					</div>
					<v-simple-table class="bt-table listing-table table-head-sticky">
						<thead>
							<tr>
								<th class="simple-table-th" width="40px">#</th>
								<th class="simple-table-th">Expense</th>
								<th class="simple-table-th">Title</th>
								<th class="simple-table-th">Amount</th>
								<th class="simple-table-th">Remark</th>
							</tr>
						</thead>
						<tbody v-if="spendList.length">
							<tr class="cursor-pointer" v-for="(row, index) in spendList" :key="index">
								<td>{{ index + 1 }}</td>
								<td>
									<div class="d-flex flex-column">
										<Chip
											:text="row.barcode"
											tooltip-text="Claim-Expense"
											tooltip
											small
											color="cyan"
											class="cursor-pointer"
										></Chip>
										<Chip
											:text="row.expense_type"
											tooltip
											tooltip-text="Expense Type"
											small
											:color="row.expense_type === 'individual' ? 'orange' : 'green'"
											class="mt-2 cursor-pointer"
										></Chip>
									</div>
								</td>
								<td width="250px">
									<ShowValue truncate :object="row" object-key="title" label="title"></ShowValue>
								</td>
								<td>
									<ShowPrice truncate :object="row" object-key="amount" label="amount"></ShowPrice>
								</td>
								<td>
									<span class="line-clamp-3" style="max-width: 450px">
										<ShowTextAreaValue :object="row" object-key="remark" label="remark"> </ShowTextAreaValue>
									</span>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr>
								<td colspan="11">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no expense at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</div>
			</v-tab-item>
			<v-tab-item value="purchase-order" v-if="tabs === 'purchase-order'">
				<div>
					<div class="d-flex pb-2">
						<inline-svg
							fill="#2666cb"
							:src="$assetURL('media/custom-svg/purchase-order.svg')"
							height="20"
							width="20"
							class="mr-2"
						/>
						<h5 style="color: #0d47a1">Purchase Order Spend</h5>
					</div>
					<v-simple-table class="bt-table listing-table table-head-sticky">
						<thead>
							<tr>
								<th class="simple-table-th" width="40px">#</th>
								<th class="simple-table-th">Purchase Order</th>
								<th class="simple-table-th">Detail</th>
								<th class="simple-table-th">Customer</th>
								<th class="simple-table-th">Supplier</th>
								<!-- <th class="simple-table-th">Created At</th> -->
							</tr>
						</thead>
						<tbody v-if="purchaseOrderExpense.length">
							<tr class="cursor-pointer" v-for="(row, index) in purchaseOrderExpense" :key="index">
								<td>{{ index + 1 }}</td>
								<td>
									<div class="d-flex flex-column">
										<Chip
											:text="row.barcode"
											tooltip-text="Claim-Expense"
											tooltip
											small
											color="cyan"
											class="cursor-pointer"
										></Chip>
										<Chip
											:text="row.status_value"
											tooltip
											tooltip-text="Expense Type"
											small
											:color="row.status_value === 'draft' ? 'orange' : 'green'"
											class="mt-2 cursor-pointer"
										></Chip>
									</div>
								</td>
								<td width="250px">
									<div>
										<span class="fw-500">ref : </span>
										<ShowValue truncate :object="row" object-key="reference_no" label="Reference No" />
									</div>
									<div>
										<span class="fw-500">Amount : </span>
										<ShowPrice truncate :object="row" object-key="amount" label="Amount" />
									</div>
									<div>
										<span class="fw-500">Delivery Date : </span>
										<template v-if="row.delivery_date">
											{{ formatDate(row.delivery_date) }}
										</template>
										<template v-else>
											<em class="text-muted">no Delivery Date</em>
										</template>
									</div>
								</td>
								<td>
									<div>
										<v-icon small left>mdi-home-city-outline</v-icon>
										<ShowValue
											tooltip
											tooltip-text="company name"
											:object="row"
											object-key="customer_company_name"
											label="company name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="display name"
											:object="row"
											object-key="customer_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="customer_email"
											label="email"
										></ShowValue>
									</div>
								</td>
								<td>
									<div>
										<v-icon small left>mdi-home-city-outline</v-icon>
										<ShowValue
											tooltip
											tooltip-text="Supplier Company name"
											:object="row"
											object-key="supplier_company_name"
											label="supplier company name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="supplier name"
											:object="row"
											object-key="supplier_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="customer_email"
											label="email"
										></ShowValue>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr>
								<td colspan="11">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no purchase order expense at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</div>
			</v-tab-item>
		</v-tabs-items>
		<!-- <div class="w-100 d-flex justify-space-between mt-6">
			
			<v-divider vertical></v-divider>
	
		</div> -->
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getExpenseAndPO } from "@/core/lib/project.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import ShowPrice from "@/view/components/ShowPrice";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import Chip from "@/view/components/Chip";
export default {
	name: "expense-listing",
	title: "expense Listing",
	components: { ShowValue, ShowTextAreaValue, ShowPrice, Chip },
	data() {
		return {
			spendList: [],
			purchaseOrderExpense: [],
			budget: null,
			detail: null,
			tabs: "expense-spend",
		};
	},
	methods: {
		async getExpenseById() {
			try {
				this.expenseDetailDialog = true;
				const data = await getExpenseAndPO(
					this.$route.params.uuid,
					this.$route.params.budget_id,
					this.$route.query.sub_budget
				);
				this.detail = data;
				this.spendList = data?.expenses;
				this.purchaseOrderExpense = data?.purchase_orders;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.getExpenseById();
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				text: "Project",
				disabled: true,
				href: `/project/${this.$route.params.uuid}?t${new Date().getTime()}&tab=budget`,
			},
			{ text: "Expense Detail", disabled: true, href: "#" },
		]);
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
